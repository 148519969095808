<!--
 * 
 *赠予管理
-->

<template>
  <div>
    <div class="gift-box" :style="!isApp ? 'padding-top:70px' : ''">
      <!-- <s-header :name="'赠予管理'"></s-header> -->
      <van-tabs
        @click="onChangeTab"
        :color="'rgba(255, 255, 255, 0.7)'"
        background="#1C172A"
        line-height="0"
        :title-active-color="'#fff'"
        class="gift-tab"
        v-model="type"
      >
        <!-- <div class="dot myDot"></div> -->
        <div class="dot getDot" v-if="pointData.receive_user"></div>
        <van-tab title="我的转赠" :name="1"> </van-tab>
        <van-tab title="收到赠与" :name="2"></van-tab>
      </van-tabs>
      <div class="content">
        <van-list class="gift-list">
          <div v-for="(item, index) in giftList" :key="index" class="gift-item-box">
            <div class="gift-item-header">
              <span>订单编号：{{ item.trade_no ? item.trade_no : item.id }}</span>
              <span>{{ getTime(item.updated_time) }}</span>
            </div>
            <div
              class="item-info-box"
              :style="item.childOrder.length > 1 ? 'padding-bottom:0;' : ''"
            >
              <div
                class="item-info"
                v-for="(orderItem, orderIndex) in item.childOrder"
                :key="orderIndex"
                :style="item.childOrder.length > 1 ? 'margin-bottom:10px;' : ''"
              >
                <div class="item-img-name">
                  <div class="item-img">
                    <img :src="orderItem.image_icon" alt="" srcset="" />
                  </div>
                  <div class="item-name">
                    <!-- <p>
                    {{
                      orderItem.open_status ? orderItem.display_name : orderItem.collections_name
                    }}
                  </p>
                  <p>{{ orderItem.items_name }}</p> -->

                    <p>{{ orderItem.display_name }}</p>
                    <p>
                      <!-- {{
                      orderItem.items_name == '盲盒'
                        ? orderItem.stage == 0
                          ? ''
                          : orderItem.stage + '期'
                        : ''
                    }} -->
                      {{ orderItem.items_name }} x {{ orderItem.num }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer" :class="item.childOrder.length > 1 ? 'multipleFooter' : ''">
              <!-- 价格 -->
              <div
                class="priceNum"
                :class="item.childOrder.length > 1 ? 'multiplePrice' : 'priceStatus'"
              >
                ¥ {{ item.price / 100 }}
              </div>

              <!-- 转增状态 -->
              <div
                v-if="item.status === 1 && type == 1"
                class="backout"
                :class="item.childOrder.length > 1 ? '' : 'btnStatus'"
                @click="backout(item.id)"
              >
                撤销转赠
              </div>
              <div
                v-if="item.status === 1 && type == 2"
                class="confirmSend"
                :class="item.childOrder.length > 1 ? '' : 'btnStatus'"
                @click="confrmSend(item.trade_no)"
              >
                确认签收
              </div>
              <div
                v-if="item.status === 2 && type == 1"
                :class="item.childOrder.length > 1 ? '' : 'btnStatus'"
              >
                已转赠
              </div>
              <div
                v-if="item.status === 2 && type == 2"
                :class="item.childOrder.length > 1 ? '' : 'btnStatus'"
              >
                已收到
              </div>
            </div>
          </div>
        </van-list>
        <div v-if="isLoading" class="dataNullBox">
          <div>
            <img
              src="./../imgs/loading/loading.gif"
              style="width: 2rem; height: 2rem"
              alt=""
              srcset=""
            />
          </div>
          <div class="text">数据加载中……</div>
        </div>
        <div v-else-if="isLoadError" class="dataNullBox">
          <div>
            <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
          </div>
          <div class="text" @click="getList">数据加载失败，请检查网络设置后刷新页面</div>
        </div>
        <div v-else-if="!giftList.length" class="dataNullBox">
          <div>
            <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
          </div>
          <div class="text">暂无数据</div>
        </div>
      </div>

      <popup
        v-model:show.sync="show"
        title="撤销转赠"
        desc="确定撤销转赠吗？"
        :btncon="{ cancel: '取消', success: '确认撤销' }"
        :confirmColor="'#E63F3F'"
        @confirm="cancelSend"
        @cancel="show = false"
      />

      <popup
        v-model:show.sync="receivePop"
        title="签收成功"
        :desc="'您已成功签收来自' + user_name + '的' + count + '个藏品，是否进入“我的藏品”查看？'"
        :btncon="{ cancel: '取消', success: '查看' }"
        @confirm="toCollections"
        @cancel="receivePop = false"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';

import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import popup from '@/components/popup/index.vue';
import {
  contributeList,
  cancelCollection,
  acceptCollection,
  acceptbyprice,
  sandpayCloud
} from '@/service/user';
import { delhotdot } from '@/service/home';
import moment from 'moment';
import { nftUtils } from '@/utils';

export default {
  name: 'gift',
  components: {
    popup
  },
  setup() {
    const route = useRoute();
    const toastFn = inject('$toast');
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      type: 1,
      list: [],
      show: false,
      giftList: [],
      orderId: null,
      isApp: nftUtils.isInApp(),
      pointData: {},
      delDotFlag: true,
      receivePop: false,
      user_name: null,
      count: null,
      isLoading: false,
      isLoadError: false
    });

    // 获取列表
    const getList = () => {
      state.isLoading = true;
      state.isLoadError = false;
      contributeList({
        type: state.type
      })
        .then(res => {
          console.log(res);
          state.giftList = res.data;
          state.isLoading = false;
          state.isLoadError = false;
        })
        .catch(err => {
          state.isLoading = false;
          state.isLoadError = true;
        });
    };

    // tab切换
    const onChangeTab = name => {
      // 这里 Tab 最好采用点击事件，@click，如果用 @change 事件，会默认进来执行一次。
      state.type = name;
      state.giftList = [];
      getList();
      if (name == 2 && state.delDotFlag) {
        (state.pointData = {}),
          delhotdot({ type: 1 }).then(res => {
            console.log(res);
            if (res.status == 0) {
              store.commit('resetSendPoint');
            }
            state.delDotFlag = false;
          });
      }
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm') => {
      return moment(time).format(format);
    };

    const onLock = () => {
      state.btnFlag = true;
      setTimeout(() => {
        state.btnFlag = false;
      }, 1000);
    };

    // 确认签收
    const confrmSend = id => {
      if (state.btnFlag) {
        return;
      }
      onLock();

      nftUtils.setcnzzRecordByBtn('确认签收');
      // acceptCollection({ id: id }).then(res => {
      //   console.log(res);
      //   getList();
      //   if (res.status !== 0) {
      //     toastFn({ message: res.message });
      //     return false;
      //   } else {
      //     toastFn({ message: '签收成功' });
      //   }
      // });
      acceptbyprice({
        trade_no: id,
        back_url: window.location.href
      }).then(res => {
        console.log(res);
        if (res.status == 421) {
          toastFn({ message: res.message, duration: 3000 });
          sandpayCloud({}).then(resIn => {
            console.log(resIn);
            if (resIn.status == 0) {
              window.location.href = resIn.data.url;
            }
          });
        } else if (res.status == 0) {
          if (res.data.url) {
            window.location.href = res.data.url;
          } else {
            getList();
            toastFn({ message: '签收成功' });
          }
        } else {
          getList();
          toastFn({ message: res.message, duration: 3000 });
        }
      });
    };

    // 打开撤回转赠弹窗
    const backout = id => {
      state.orderId = id;
      state.show = true;
    };

    // 确认撤回转赠
    const cancelSend = () => {
      if (state.btnFlag) {
        return;
      }
      onLock();
      nftUtils.setcnzzRecordByBtn('撤回转赠');
      cancelCollection({ id: state.orderId }).then(res => {
        console.log(res);
        state.show = false;
        getList();
        if (res.status !== 0) {
          toastFn({ message: res.message });
          return false;
        }
      });
    };
    const getnewurl = name => {
      var loca = window.location;
      var baseUrl = loca.origin + loca.pathname + '?';
      var query = loca.search.substr(1);
      if (query.indexOf(name) > -1) {
        var obj = {};
        var arr = query.split('&');
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split('=');
          obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        var url =
          baseUrl +
          JSON.stringify(obj)
            .replace(/[\"\{\}]/g, '')
            .replace(/\:/g, '=')
            .replace(/\,/g, '&');
        return url;
      }
    };

    // 是否弹窗
    const isPop = () => {
      // '?user_name=' + transInfo.user_name + '&count=' + transInfo.count
      state.user_name = route.query.user_name;
      state.count = route.query.count;
      if (state.user_name && state.count) {
        state.receivePop = true;
        delete route.query.count;
        getnewurl('count');
        // console.log(getnewurl('count'));
        window.history.replaceState(null, null, getnewurl('count'));
        console.log('route.query', route.query);
      }
      // route.query.id
      // if()
    };

    onMounted(async () => {
      getList();
      isPop();
      // await store.commit('resetSendPoint');
      await store.dispatch('gethotdot');
      state.pointData = store.state.pointData;
      console.log('store.state.sendPoint', store.state.pointData);
      if (state.pointData.send_user) {
        delhotdot({ type: 2 }).then(res => {
          console.log(res);
          if (res.status == 0) {
            store.commit('resetSendPoint');
          }
        });
      }
    });

    // 查看藏品
    const toCollections = () => {
      state.receivePop = false;
      router.replace({ path: '/collections' });
    };

    return {
      ...toRefs(state),
      onChangeTab,
      getList,
      getTime,
      confrmSend,
      cancelSend,
      backout,
      onLock,
      toCollections
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.dot {
  width: 6px;
  height: 6px;
  background: #e63f3f;
  opacity: 1;
  border-radius: 50%;
  position: absolute;

  top: 10px;
}
.myDot {
  left: 122px;
}
.getDot {
  right: 60px;
}

.gift-box {
  .gift-tab {
    // position: fixed;
    // left: 0;
    // z-index: 100;
    // width: 100%;
    // line-height: 44px;
  }

  .content {
    // position: relative;
    // height: calc(100vh - 44px);
    // overflow: hidden;
    // overflow-y: scroll;
    // margin-top: 44px;
    padding-bottom: 90px;
  }

  .dataNullBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 150px;
      height: 110px;
    }

    .text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .gift-list {
    padding: 0 16px;

    .gift-item-box {
      position: relative;
      background: #2f293f;
      border-radius: 8px 8px 8px 8px;
      margin-top: 24px;

      .gift-item-header {
        padding: 0 14px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.7);
        line-height: 38px;
        border-bottom: 1px solid #494359;
      }

      .item-info-box {
        padding: 20px 14px;
      }

      .item-info {
        // padding: 20px 14px;

        .item-img-name {
          display: flex;
          justify-content: flex-start;

          p {
            margin: 0;
          }

          .item-img {
            font-size: 0;
          }

          img {
            width: 70px;
            height: 70px;
            border-radius: 4px 4px 4px 4px;
            object-fit: cover;
          }

          .item-name {
            margin-left: 12px;

            :first-child {
              font-size: 16px;
              font-weight: 500;
              color: #fff;
            }

            :last-child {
              font-size: 12px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }

      .footer {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        line-height: 26px;
      }

      .multipleFooter {
        border-top: 1px solid #494359;
        padding: 10px 14px;
        position: relative;
      }

      .multiplePrice {
        position: absolute;
        right: 110px;
      }

      .btnStatus {
        position: absolute;
        right: 14px;
        bottom: 15px;
      }

      .priceStatus {
        position: absolute;
        right: 110px;
        bottom: 15px;
      }

      .priceNum {
        font-size: 16px;
        font-weight: 500;
        color: #3cdbe8;
        line-height: 26px;
      }

      .backout {
        padding: 0 17px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }

      .confirmSend {
        padding: 0 17px;
        color: black;
        border-radius: 4px 4px 4px 4px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      }
    }
  }
}
</style>
